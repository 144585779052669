<template>
  <div class="flow-root">
    <div class="relative pb-8">
      <div>
        <span
          v-if="index !== length - 1"
          class="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
          aria-hidden="true"
        />
        <div class="relative flex space-x-3">
          <div>
            <!-- This only supports the update icon -->
            <span class="h-8 w-8 rounded-full flex items-center justify-center bg-gray-200">
              <FontAwesomeIcon :icon="['far', 'pen-to-square']" />
            </span>
          </div>
          <div class="flex min-w-0 flex-1 space-x-2 pt-1.5">
            <div>
              <p class="font-bold text-gray-800">{{ history.createdBy.firstName }} {{ history.createdBy.lastName }}</p>
              <p class="font-medium text-gray-500 text-left">
                {{ createdAt }}
              </p>
            </div>
            <div class="wrap text-gray-800 text-left">
              <p>
                {{ diff }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDistance } from 'date-fns';

/**
 * This component will currently only support visualization of the update of the diff.
 * Future iterations will include the ability to view the diff in full (add/remove).
 */
export default {
  props: {
    history: Object,
    index: Number,
    length: Number
  },
  computed: {
    createdAt() {
      const now = new Date();
      const created = new Date(this.history.createdAt);

      const timeAgo = formatDistance(created, now) + ' ago';
      return timeAgo;
    },
    diff() {
      const { diff } = this.history;
      let diffString = 'edited ';

      for (const key in diff.updated) {
        diffString += `${this.formatKeyName(key)}, `;
      }

      return diffString.slice(0, -2);
    }
  },
  methods: {
    formatKeyName(variableName) {
      return variableName
        .split(/(?=[A-Z])/)
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }
  }
};
</script>
