<template>
  <div>
    <RbTextArea @submit="submitComment" class="mb-2" />
    <div v-if="comments.length">
      <RbInfiniteScroll
        @loadMore="fetchData"
        @recoverFromError="recoverFromError"
        :loading="loading"
        :errorMessage="error"
        :height="600"
        :bottomOffset="200"
        :preventLoad="loadedAll"
      >
        <Comment v-for="comment in comments" :comment="comment" :key="comment.id" />
      </RbInfiniteScroll>
    </div>
    <div v-else>
      <p>No comments</p>
    </div>
  </div>
</template>

<script>
import Comment from '@/components/ui/Comment';
import CommentServiceV2 from '@/lib/comment-service-v2';
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';

export default {
  components: {
    Comment
  },
  props: {
    orderId: {
      type: String
    }
  },
  mounted() {
    this.fetchData();
  },
  data() {
    return {
      loading: true,
      comments: [],
      currentPage: 0,
      pageSize: 10,
      sortBy: 'createdAt',
      sortDir: 'desc',
      error: null,
      loadedAll: false
    };
  },
  methods: {
    async fetchData() {
      this.loading = true;

      const params = {
        orderId: this.orderId,
        page: this.currentPage,
        pageSize: this.pageSize,
        sortBy: this.sortBy,
        sortDir: this.sortDir
      };

      const { data, pagination } = await CommentServiceV2.listComments(params);

      const updated = [...this.comments, ...data];
      this.comments = updated;

      this.currentPage = pagination.page + 1;

      if (pagination.total === updated.length) {
        this.loadedAll = true;
      }

      this.loading = false;
    },
    async recoverFromError() {
      this.error = null;
      this.page = 0;
      this.fetchData();
    },
    async submitComment(textContent) {
      const sessionUser = await getAuth().sessionUser();

      if (textContent) {
        try {
          await CommentServiceV2.createComment({
            orderId: this.orderId,
            content: textContent
          });

          const newComment = {
            content: textContent,
            userName: sessionUser.name,
            createdAt: new Date().toISOString()
          };

          const existing = this.comments;
          const pushToTop = [newComment, ...existing];

          this.comments = pushToTop;
        } catch (error) {
          this.errorMessage = this.parseError(error).message;
        }
      }
    }
  }
};
</script>
