<template>
  <div>
    <HistoryItem :index="0" :length="2" />
    <HistoryItem :index="1" :length="2" />
    <div v-if="histories.length">
      <RbInfiniteScroll
        @loadMore="fetchData"
        @recoverFromError="recoverFromError"
        :loading="loading"
        :errorMessage="error"
        :height="600"
        :bottomOffset="200"
        :preventLoad="loadedAll"
      >
        <HistoryItem
          v-for="(history, index) in histories"
          :history="history"
          :index="index"
          :length="histories.length"
          :key="history.id"
        />
      </RbInfiniteScroll>
    </div>
    <div v-else>
      <p>No Change Logs Available</p>
    </div>
  </div>
</template>

<script>
import HistoryService from '@/lib/history-service-v2';
import HistoryItem from '@/components/ui/HistoryItem.vue';

export default {
  components: {
    HistoryItem
  },
  props: {
    orderId: {
      type: String
    }
  },
  mounted() {
    this.fetchData();
  },
  data() {
    return {
      loading: true,
      histories: [],
      currentPage: 0,
      pageSize: 10,
      sortBy: 'createdAt',
      sortDir: 'desc',
      error: null,
      loadedAll: false
    };
  },
  methods: {
    async fetchData() {
      this.loading = true;

      const params = {
        page: this.currentPage,
        pageSize: this.pageSize,
        sortBy: this.sortBy,
        sortDir: this.sortDir
      };

      const { data, pagination } = await HistoryService.getOrderHistory(this.orderId, params);

      const updated = [...this.histories, ...data];
      this.histories = updated;

      this.currentPage = pagination.page + 1;

      if (pagination.total === updated.length) {
        this.loadedAll = true;
      }

      this.loading = false;
    },
    async recoverFromError() {
      this.error = null;
      this.page = 0;
      this.fetchData();
    }
  }
};
</script>
